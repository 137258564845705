<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Modificar Idioma</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                label="Nombre del Idioma"
                required
                outlined
                hide-details
                v-model="language.language"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="update()"
          :loading="isLoading"
        >
          Modificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UpdateLanguage",
  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  props: { language: Object, clean: { type: Function } },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    update: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + "language/" + this.language.id_language,
          this.toFormData(this.language)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
  },
};
</script>

<style></style>
